/*  Again with flexbox*/
/* Flex-related code */
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Or space-between or space-around */
}

.flex > section {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  text-align: center;
  max-width: 400px;
  padding: 1em;
  margin: 0.5em;
  border-radius: 4px;
  margin-top: auto;
}

.flex > section > p {
  flex-grow: 1;
}

.flex > section > img {
  vertical-align: middle;
}

.flex aside {
  width: 100%;
  margin-top: auto;
}
