.myfloatingbutton {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #848383ff;
  color: #88c24eff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #333;
}

.my-float {
  margin-top: 22px;
}
